import React, { useCallback, Suspense, useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { useAtom } from 'jotai'
import { Text } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import DesktopView from './DesktopView'
import { getOpportunities, getTasksByEmail } from '../../api'
import { userProfile } from '../../utils/atom'
import MobileView from './MobileView'

const MyApplication = () => {
  const isFocused = useIsFocused()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [userprofile] = useAtom(userProfile)
  const [activeTab, setActiveTab] = useState(0)
  const [offeredCards, setOfferedCards] = useState([])
  const [data, setData] = useState({
    Offer: [],
    Submitted: [],
    Rejected: [],
  })
  const { t } = useTranslation()
  const [brandLogoUrl, setBrandLogoUrl] = useState('')

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const fetchAllOpportunities = async () => {
    let response = await getOpportunities({ email: userprofile?.email })
    if (!response) {
      response = await getOpportunities({ email: userprofile?.email })
    }

    if (response?.response) {
      const allData = response?.response

      const admissionsReview = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Admission Review',
      )
      const offer = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Offers',
      )

      const deposit = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Deposit',
      )

      const accepted = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Accepted',
      )
      const enrolled = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Enrolment',
      )
      const rejected = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Withdrawn',
      )
      setData({
        ...data,
        Submitted: admissionsReview,
        Offer: [...offer, ...accepted, ...enrolled, ...deposit],
        Rejected: rejected,
      })
      setOfferedCards(offer)
    }
    return response?.response
  }

  const { data: opportunities, isFetching: isOpportunitiesFetching } = useQuery(
    {
      queryKey: ['getOpportunities'],
      queryFn: fetchAllOpportunities,
      enabled: isFocused && !!userprofile?.email,
    },
  )

  const { data: tasks, isFetching: isFetchingTask } = useQuery({
    queryKey: [`fetchTasks-${userprofile?.email}`],
    queryFn: () => getTasksByEmail({ email: userprofile.email }),
    enabled: isFocused && !!userprofile?.email,
  })

  const toggleDropdown = (visible, ref) => {
    if (visible) {
      return
    }
    if (!visible) {
      ref?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
        setDropdownTop(py + 50)
        setDropdownLeft(_px)
        setDropDownWidth(_w)
      })
    }
  }
  const onChangeDropdown = (item, index) => {
    setActiveTab(index)
  }
  const filterData = [
    {
      fieldName: 'Submitted',
      label: t('MY_APPLICATION.SUBMITTED'),
      count: data?.Submitted?.length || 0,
      errorMessageDescription: t('EMPTY_CONTAINER.NO_SUBMITTED_APPLICATIONS'),
      errorMessageTitle: t('EMPTY_CONTAINER.HEADING_1'),
    },
    {
      fieldName: 'Offer',
      label: t('MY_APPLICATION.OFFERS'),
      count: data?.Offer?.length || 0,
      errorMessageDescription: t('EMPTY_CONTAINER.NO_OFFERS_MESSAGE'),
      errorMessageTitle: t('EMPTY_CONTAINER.HEADING_1'),
    },
    {
      fieldName: 'Rejected',
      label: t('MY_APPLICATION.REJECTED'),
      count: data?.Rejected?.length || 0,
      errorMessageDescription: t('EMPTY_CONTAINER.NO_REJECTION_MESSAGE'),
      errorMessageTitle: t('EMPTY_CONTAINER.HEADING_1'),
    },
  ]

  useEffect(() => {
    if (!isFetchingTask && !isOpportunitiesFetching && opportunities) {
      opportunities?.map((opportunity) => {
        opportunity.ActionRequiredItems = tasks?.response?.find(
          (task) => task.Id === opportunity.Id,
        )?.ActionRequiredItems

        opportunity.ActionRequiredCount = tasks?.response?.find(
          (task) => task.Id === opportunity.Id,
        )?.ActionRequiredCount
        return opportunity
      })

      const allData = opportunities

      const admissionsReview = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Admission Review',
      )
      const offer = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Offers',
      )

      const deposit = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Deposit',
      )

      const accepted = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Accepted',
      )
      const enrolled = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Enrolment',
      )
      const rejected = allData?.filter(
        (item) => item?.ApplicationStatus?.category === 'Withdrawn',
      )
      setData({
        ...data,
        Submitted: admissionsReview,
        Offer: [...offer, ...accepted, ...enrolled, ...deposit],
        Rejected: rejected,
      })
      setOfferedCards(offer)
    }
  }, [isFetchingTask, isOpportunitiesFetching, tasks, opportunities])

  useEffect(() => {
    const counts = {
      submitted: 0,
      offers: 0,
      rejected: 0,
    }

    // Assign counts based on filterData
    filterData.forEach((item) => {
      if (item.fieldName === 'Submitted') counts.submitted = item.count
      if (item.fieldName === 'Offer') counts.offers = item.count
      if (item.fieldName === 'Rejected') counts.rejected = item.count
    })

    const totalApplications = counts.submitted + counts.offers + counts.rejected

    if (totalApplications === 1) {
      if (counts.offers > 0) setActiveTab(1)
      else if (counts.submitted > 0) setActiveTab(0)
      else if (counts.rejected > 0) setActiveTab(2)
    } else {
      if (counts.offers > 0) setActiveTab(1)
      else if (counts.submitted > 0) setActiveTab(0)
      else if (counts.rejected > 0) setActiveTab(2)
    }
  }, [data])

  const userName = userprofile.firstName + ' ' + userprofile.lastName
  const viewProps = {
    activeTab,
    brandLogoUrl,
    data,
    filterData,
    isLoading: isOpportunitiesFetching || isFetchingTask,
    offeredCards,
    setActiveTab,
    toggleDropdown,
    dropdownTop,
    dropdownLeft,
    dropdownWidth,
    onChangeDropdown,
    userprofile,
    userName,
    tasks,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default MyApplication
