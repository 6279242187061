import { France, Germany, UnitedStates } from '@apphero/assets'

export const languageDropDown = [
  {
    label: 'English',
    image: UnitedStates,
    languageCode: 'en',
  },
  {
    label: 'French',
    image: France,
    languageCode: 'fr',
  },
  {
    label: 'German',
    image: Germany,
    languageCode: 'de',
  },
]

export const menuItems = [
  {
    label: 'Account Information',
    action: 'profile',
    param: false,
  },
  {
    label: 'Change Password',
    action: 'change-password',
    param: false,
  },
]

export const profileDrawerMenu = [
  {
    label: 'Profile Information',
    action: 'profile',
    icon: 'ProfileMenu',
  },
  {
    label: 'Notifications',
    param: false,
    icon: 'NotificationBell',
    action: 'notification',
  },
  {
    label: 'Change Password',
    param: false,
    icon: 'PasswordLock',
    action: 'change-password',
  },
  {
    label: 'Logout',
    param: false,
    icon: 'Logout',
    action: 'logout',
  },
]
export const profileDrawerTabs = [
  {
    label: 'Dashboard',
    action: 'dashboard',
    isTab: true,
  },
  {
    label: 'MY APPLICATIONS',
    action: 'my-application',
    isTab: true,
  },
  {
    label: 'EXPLORE PATHWAYS',
    action: 'path-finder',
    isTab: true,
  },
  {
    label: 'Action Required',
    action: 'action-required',
    isTab: true,
  },
]

export const profileDrawerTabsWithoutPathWay = [
  {
    label: 'Dashboard',
    action: 'dashboard',
    isTab: true,
  },
  {
    label: 'My Applications',
    action: 'my-application',
    isTab: true,
  },
  {
    label: 'Action Required',
    action: 'action-required',
    isTab: true,
  },
]
export const MobileMenuItems = [
  {
    label: 'Account Information',
    action: 'profile',
    param: false,
  },
  {
    label: 'Change Password',
    action: 'change-password',
    param: false,
  },
  {
    label: 'Logout',
    action: 'logout',
  },
]

export const loadingMessage = {
  login: { label: 'Signing In...' },
  'forgot-password': {
    label: 'Resetting password...',
    subText:
      'Redirecting you to login page. Please login with your new credentials!',
  },
  'set-password': {
    label: 'Account creation in progress',
  },
  otp: { label: 'Signing Up...' },
}

export const timeLineData = [
  { id: 1, title: 'DRAFTS', status: 'Draft' },
  { id: 2, title: 'ADMISSIONS REVIEW', status: 'Admission Review' },
  { id: 3, title: 'OFFER', status: 'Offer' },
  { id: 4, title: 'DEPOSIT', status: 'Deposit' },
  { id: 5, title: 'ENROLMENT', status: 'Enrolment' },
]
