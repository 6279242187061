export const formattedStatus = (status) => {
  if (status?.split(' ').length === 2) {
    return status?.split(' ')[1]
  } else if (
    status?.split(' ').length === 3 &&
    status?.split(' ')[0] === 'Visa'
  ) {
    return 'Visa'
  } else {
    return status
  }
}
