import { View, StyleSheet, Image, useWindowDimensions } from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Badge, Button, Text } from '@libs/components'
import { DateFormat } from '@libs/utils'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@libs/theme'
import { isWeb } from '@libs/utils/src/screenLayout'
import { formatIntakeDate } from '@libs/utils/src/dateformat'
import { badgeColor, excludedDurationBrands } from '../../constants'
import { formattedStatus } from '../../utils/formattedStatus'

const OpportunitiesCard = ({ data, brandLogo, height, width }) => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [, setShowBrandName] = useState(false)
  const { t } = useTranslation()
  const { width: screenWidth } = useWindowDimensions()
  const isDesktop = isWeb(screenWidth)

  const duration = data?.OpportunityLineItems?.records[0]?.Product2?.Duration__c
  let opportunitiesDetails = [
    {
      label: t('APPLICATION_DETAIL.INTAKE'),
      value: data.IntakeDate ? formatIntakeDate(data.IntakeDate) : '-',
      separate: false,
    },
    {
      value: duration || '-',
      label: t('APPLICATION_DETAIL.DURATION'),
      separate: false,
    },
    {
      label: t('APPLICATION_DETAIL.LOCATION'),
      value:
        data?.Location__c ??
        data?.OpportunityLineItems?.records?.[0]?.Location__c ??
        '-',
      separate: false,
    },
    {
      label: !isDesktop
        ? t('APPLICATION_DETAIL.CREATED_DATE')
        : t('APPLICATION_DETAIL.APPLICATION_CREATED_DATE'),
      value: DateFormat(data?.CreatedDate?.split('T')[0]) || '-',
      separate: false,
    },
    {
      label: !isDesktop
        ? t('APPLICATION_DETAIL.SUBMITTED_DATE')
        : t('APPLICATION_DETAIL.SUBMITTED_DATE'),
      value: data?.Application_Submitted_Date__c
        ? DateFormat(data?.Application_Submitted_Date__c?.split('T')[0])
        : '-',
      separate: false,
    },
  ]

  if (excludedDurationBrands.includes(data.BusinessUnitFilter__c)) {
    opportunitiesDetails = opportunitiesDetails.filter(
      (item) => item.label !== t('APPLICATION_DETAIL.DURATION'),
    )
  }

  const programName = data?.ProgrammeName__c
    ? `${data?.ProgrammeName__c}${
        data?.Delivery_Mode__c ? `, ${data?.Delivery_Mode__c}` : ''
      }`
    : ''

  return (
    <View style={styles.mainContainer}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 8,
          flexWrap: !isDesktop ? 'wrap-reverse' : 'nowrap',
        }}
      >
        <Image
          source={brandLogo}
          style={{
            height,
            width,
          }}
          alt={data?.brand}
          resizeMode="contain"
          onError={() => {
            setShowBrandName(true)
          }}
        />
        {isDesktop ? (
          <View
            style={{
              justifyContent: 'flex-start',
              flex: 1,
              alignSelf: 'baseline',
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                fontSize: 16,
                color: colors.cardTitleColor,
                paddingRight: 24,
              }}
            >
              {programName ? programName : data?.Name?.split('_')[0]}
            </Text>
          </View>
        ) : null}

        <View
          style={{
            justifyContent: 'flex-start',
            alignSelf: isDesktop ? 'baseline' : 'center',
            flexGrow: 0,
          }}
        >
          <Badge
            style={[
              badgeColor[data?.ApplicationStatus?.status.split(' ')[0]] ||
                badgeColor[data?.ApplicationStatus?.status.split('/')[0]],
            ]}
            textVariant="display4"
          >
            Status : {formattedStatus(data?.ApplicationStatus?.status)}
          </Badge>
        </View>
      </View>
      {!isDesktop ? (
        <View
          style={{
            justifyContent: 'flex-start',
            flex: 1,
            alignSelf: 'baseline',
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              fontSize: 16,
              color: colors.cardTitleColor,
            }}
          >
            {programName ? programName : data?.Name?.split('_')[0]}
          </Text>
        </View>
      ) : null}

      {isDesktop ? (
        <View
          style={{
            marginLeft: 123,
            marginTop:
              window.devicePixelRatio.toFixed(1) == 2.2 ||
              window.devicePixelRatio.toFixed(1) == 2.5
                ? 12
                : 0,
            flexDirection: window.innerWidth <= 1117 ? 'column' : 'row',
            gap: window.innerWidth <= 1117 ? 10 : 47,
          }}
        >
          <View style={{ gap: 10 }}>
            {opportunitiesDetails?.slice(0, 3).map((item) => (
              <Item item={item} isDesktop={isDesktop} />
            ))}
          </View>
          <View style={{ gap: 10 }}>
            {opportunitiesDetails?.slice(3, 6).map((item) => (
              <Item item={item} isDesktop={isDesktop} />
            ))}
          </View>
        </View>
      ) : (
        <View
          style={{
            marginTop: 12,
            flexDirection: window.innerWidth >= 500 ? 'row' : 'column',
            gap: window.innerWidth <= 500 ? 10 : 32,
          }}
        >
          <View style={{ gap: 10 }}>
            {opportunitiesDetails?.slice(0, 3).map((item) => (
              <Item item={item} isDesktop={isDesktop} />
            ))}
          </View>
          <View style={{ gap: 10 }}>
            {opportunitiesDetails?.slice(3, 6).map((item) => (
              <Item item={item} isDesktop={isDesktop} />
            ))}
          </View>
        </View>
      )}

      <View style={styles.divider(colors)} />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: window.innerWidth <= 450 ? 6 : 17,
          flexWrap: 'wrap-reverse',
        }}
      >
        <Button
          onPress={() =>
            navigation.navigate('view-application-details', {
              id: data.Id,
              isActive:
                data?.ActionRequiredCount > 0
                  ? 'ACTION_REQUIRED'
                  : 'INFORMATION',
            })
          }
          appearance="outline"
          label={t('BUTTON.VIEW_APPLICATION')}
          labelVariant={!isDesktop ? 'display6' : 'display5'}
          labelStyle={{
            color: colors.buttonPrimary,
            fontWeight: '500',
          }}
          buttonStyle={styles.buttonStyleOutlined(colors, isDesktop)}
        />
        <Button
          onPress={() =>
            navigation.navigate('view-application-details', {
              id: data.Id,
              isActive: 'ACTION_REQUIRED',
            })
          }
          appearance="filled"
          buttonColor={colors.buttonPrimary}
          label={`${t(
            'BUTTON.ACTION_REQUIRED',
          )} ${`(${data.ActionRequiredCount})`}`}
          disable={data?.ActionRequiredCount < 1}
          labelVariant={!isDesktop ? 'display6' : 'display5'}
          labelStyle={{ color: colors.white, fontWeight: '500' }}
          buttonStyle={styles.buttonStyleFilled(isDesktop)}
        />
      </View>
    </View>
  )
}

const Item = ({ item, isDesktop }) => (
  <View style={styles.item(isDesktop)}>
    <Text
      variant="heading6"
      color={!isDesktop ? '#545B61' : '#424242'}
      style={{
        textAlign: 'left',
        marginRight: 5,
        fontWeight: '400',
      }}
    >
      {`${item?.label}:`}
    </Text>
    <Text
      style={{
        fontWeight: window.innerWidth <= 500 ? '700' : '400',
        fontSize: 14,
        color: !isDesktop ? '#021547' : '#757575',
      }}
    >
      {item?.value}
    </Text>
  </View>
)

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 10,
    backgroundColor: '#fff',
    paddingHorizontal: 28,
    paddingVertical: 30,
  },
  item: () => ({
    flexDirection: window.innerWidth >= 376 ? 'row' : 'column',
    flexWrap: 'wrap',
    gap: window.innerWidth <= 376 ? 6 : 0,
    justifyContent: window.innerWidth <= 500 ? 'space-between' : 'flex-start',
  }),
  divider: (colors) => ({
    borderWidth: 0.5,
    borderColor: colors.divider,
    borderStyle: 'solid',
    marginVertical: 24,
  }),
  buttonStyleOutlined: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 40 : 20,
    paddingVertical: 10,
    borderColor:
      window.innerWidth <= 450 ? 'transparent' : colors.primaryContainer,
    width: window.innerWidth <= 450 ? '100%' : 'auto',
  }),
  buttonStyleFilled: (isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 40 : 20,
    paddingVertical: 10,
    width: window.innerWidth <= 450 ? '100%' : 'auto',
  }),
})

export default OpportunitiesCard
