export const badgeColor = {
  Offer: {
    color: '#009951',
    backgroundColor: '#CFF7D3',
  },
  Accepted: {
    color: '#009951',
    backgroundColor: '#CFF7D3',
  },
  Enrolment: {
    color: '#009951',
    backgroundColor: '#CFF7D3',
  },
  Admission: {
    color: '#D4691B',
    backgroundColor: '#FFF4E0',
  },
  Draft: {
    backgroundColor: '#FFF4E0',
    color: '#D4691B',
  },
  Visa: {
    backgroundColor: '#FFF4E0',
    color: '#D4691B',
  },
  Deposit: {
    backgroundColor: '#FFF4E0',
    color: '#D4691B',
  },
  Withdrawn: {
    backgroundColor: '#FDD3D0',
    color: '#C00F0C',
  },
}
