import { Text } from 'react-native'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ScreenLayout, SecureStore } from '@libs/utils'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { t } from 'i18next'
import { Auth } from 'aws-amplify'
import { useInfiniteCMSContent } from '@app-hero/shared-hooks/src/query/useInfiniteCMSContent'
import { useUpcomingEvents, useUploadDocument } from '@app-hero/shared-hooks'
import { useSpotLightNotifications } from '@app-hero/shared-hooks/src/query/useSpotLightNotification'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import {
  getBrandTheme,
  getDocumentType,
  getOpportunities,
  getTasksByEmail,
  submitAllOpportunityTaskDocuments,
} from '../../api'
import { brandDetailsAtom, userProfile } from '../../utils/atom'
import { categorizeActionItems } from '../../utils/categorizeActionItems'
import { getProgrammeName } from '../../utils/getProgramName'
import { removeScript } from '../../utils/removeScript'
import { FW_CHAT_BOT_URL } from '../../config'

const DashBoard = (props) => {
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const [userDetails, setUserDetails] = useAtom(userProfile)
  const [modalVisible, setModalVisible] = useState(false)
  const [isFileUpload, setIsFileUpload] = useState()
  const dropDownRef = useRef()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [selectedOpportunity, setSelectedOpportunity] = useState({})
  const queryClient = useQueryClient()
  const isFocused = useIsFocused()
  const [documentType, setDocumentType] = useState([])
  const navigation = useNavigation()
  const [brandDetails, setBrandDetails] = useAtom(brandDetailsAtom)
  const allOpportunity = queryClient.getQueryData([
    `fetchOpportunities-${userDetails?.email}`,
  ])
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [summaryData, setSummaryData] = useState([])

  const { setInitialParams } = props

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      if (!userDetails?.accessToken || !userDetails?.email) {
        const currentUser = await Auth.currentAuthenticatedUser().catch(() => {
          removeScript(FW_CHAT_BOT_URL(userDetails))
          window.location.replace('login')
        })
        const userData = currentUser?.attributes

        const userDetail = {
          email: userData?.email || '',
          firstName: userData['custom:first_name'] || '',
          lastName: userData['custom:last_name'] || '',
          userID: userData?.sub || '',
          accessToken: currentUser?.signInUserSession?.accessToken?.jwtToken,
        }
        setUserDetails(userDetail)
      }
    })()
  }, [isFocused, userDetails])

  const {
    notifications,
    handleNextPage,
    handlePrevPage,
    handleUpdateNotification,
    handleEventNavigation,
    currentPage,
    hasNextPage: hasSpotLightNextPage,
    hasPrevPage,
    totalPages,
    isLoading: fetchingSpotLightNotification,
  } = useSpotLightNotifications()

  const {
    events,
    handleEventNextPage,
    handleEventPrevPage,
    currentEventPage,
    hasEventNextPage,
    hasEventPrevPage,
    totalEventPages,
    isLoading: fetchingUpcomingEvents,
  } = useUpcomingEvents()

  const fetchBrandTheme = async () => {
    const user = await Auth.currentAuthenticatedUser().catch(() => {})
    const res = await getBrandTheme({ id: props?.cobrandId })

    if (Object.keys(user || {}).length > 0 && userDetails?.email !== '') {
      if (res?.email && userDetails?.email !== res?.email) {
        setBrandDetails(res)
        Auth.signOut()
        setInitialParams({})
        await SecureStore.setItemAsync('consentAccepted', false)
        if (!res.emailStatus) {
          navigation.navigate('sign-up')
        } else if (res.emailStatus) {
          navigation.navigate('login')
        }
      }
    }
    return res
  }

  const { isFetching: isBrandThemeLoading } = useQuery({
    queryKey: ['getBrandTheme', props?.cobrandId],
    queryFn: fetchBrandTheme,
    enabled: isFocused && !!props?.cobrandId && !!allOpportunity,
    onSuccess: async (data) => {
      await SecureStore.setItemAsync(
        'tempUser',
        JSON.stringify({ email: data?.email }),
      )
    },
  })

  useEffect(() => {
    if (allOpportunity && allOpportunity.length > 1) {
      const branded = allOpportunity?.some(
        (item) => item.BusinessUnitFilter__c !== brandDetails.brand,
      )
      if (branded) {
        setBrandDetails({
          colors: {},
          uspDetails: [],
          brand: '',
          brandName: 'AppHero',
          emailStatus: null,
        })
      }
    }
  }, [allOpportunity])

  const fetchAllOpportunities = async () => {
    let response = await getOpportunities({ email: userDetails?.email })

    const branded = response.response.some(
      (item) => item.BusinessUnitFilter__c !== brandDetails.brand,
    )
    if (branded) {
      setBrandDetails({
        colors: {},
        uspDetails: [],
        brand: '',
        brandName: 'AppHero',
        emailStatus: null,
      })
    }

    if (!response) {
      response = await getOpportunities({ email: userDetails?.email })
    }

    return response?.response
  }

  const {
    data: opportunities,
    isFetching: isFetchingOpportunities,
    isLoading: isLoadingOpportunities,
  } = useQuery({
    queryKey: [`fetchOpportunities-${userDetails?.email}`],
    queryFn: fetchAllOpportunities,
    enabled: isFocused && !!userDetails?.email,
  })

  const { data: tasks, isFetching: isFetchingTask } = useQuery({
    queryKey: [`fetchTasks-${userDetails?.email}`],
    queryFn: () => getTasksByEmail({ email: userDetails.email }),
    enabled: isFocused && !!userDetails?.email,
  })

  useEffect(() => {
    if (!isFetchingTask && !isFetchingOpportunities && opportunities) {
      opportunities?.map((opportunity) => {
        opportunity.ActionRequiredItems = tasks?.response?.find(
          (task) => task.Id === opportunity.Id,
        )?.ActionRequiredItems

        opportunity.ActionRequiredCount = tasks?.response?.find(
          (task) => task.Id === opportunity.Id,
        )?.ActionRequiredCount
        return opportunity
      })
    }
  }, [isFetchingTask, isFetchingOpportunities, isLoadingOpportunities])

  const fetchDocumentTypes = async (opportunity) => {
    const documentType = await getDocumentType({
      brandName: opportunity.BusinessUnitFilter__c,
    })
    const documentList = documentType.records.map((document) => ({
      label: document.DocumentType__c,
    }))
    return documentList
  }

  const { mutation } = useUploadDocument()

  const handleFileUpload = async (properties, opportunities) => {
    setIsFileUpload(true)
    await mutation.mutateAsync({
      opportunities,
      ...properties,
    })
    setIsFileUpload(false)
  }

  const toggleDropdown = () => {
    dropDownRef?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
      setDropdownTop(py + 50)
      setDropdownLeft(_px - 10)
      setDropDownWidth(_w)
    })
  }

  const [totalActionRequiredCount, setTotalActionRequiredCount] = useState(0)

  const {
    data: actionRequiredCount,
    isFetching: isFetchingActionRequiredCount,
  } = useQuery({
    queryKey: ['getTotalActionRequiredCount'],
    enabled: true,
  })

  useEffect(() => {
    setTotalActionRequiredCount(actionRequiredCount || 0)
  }, [actionRequiredCount])

  useEffect(() => {
    setSummaryData([
      {
        iconName: 'ApplicationIcon',
        count: opportunities?.length?.toString().padStart(2, '0') || 'None',
        label: t('DASHBOARD.APPLICATIONS_SUBMITTED'),
      },
      {
        iconName: 'CheckedIcon',
        count:
          totalActionRequiredCount > 0
            ? totalActionRequiredCount?.toString().padStart(2, '0')
            : 'None',
        label: t('DASHBOARD.ACTION_REQUIRED'),
      },
    ])
  }, [opportunities, totalActionRequiredCount, isSubmitLoading])

  const handleSubmitAllOpportunityTaskFile = async (opportunityDetail) => {
    setIsSubmitLoading(true)

    const payload = {
      opportunityId: opportunityDetail?.Id,
      brand: opportunityDetail?.Brand__c,
      businessUnitFilter: opportunityDetail?.BusinessUnitFilter__c,
      email: userDetails?.email,
    }

    const response = await submitAllOpportunityTaskDocuments(payload)

    setIsSubmitLoading(false)

    if (response.ok) {
      global.showToast(
        `The <${
          opportunityDetail?.pendingActionRequiredItems?.length
        }> pending documents for <${getProgrammeName(
          opportunityDetail,
        )}> have been submitted successfully.`,
        {
          type: 'success',
          canHaveButton: true,
          buttonLabel: 'VIEW COMPLETED TASKS >',
          navigation: 'view-application-details',
          isCompleted: true,
          Id: opportunityDetail?.Id,
          title: 'Outstanding documents submitted',
        },
      )
      await queryClient.setQueryData(
        [`fetchOpportunities-${userDetails?.email}`],
        (prevData) => {
          const findOpportunityFromOpportunitiesIndex = prevData?.findIndex(
            (data) => data?.Id === opportunityDetail?.Id,
          )

          if (
            !opportunityDetail ||
            !opportunityDetail.ActionRequiredItems ||
            !opportunityDetail.ActionRequiredItems.Items
          ) {
            return prevData
          }

          // Update ActionRequiredItems
          const updatedItems = opportunityDetail.ActionRequiredItems.Items.map(
            (task) => ({
              ...task,
              Status: 'Completed',
            }),
          )

          // Update pendingActionRequiredItems
          const updatedPendingActionRequiredItems =
            opportunityDetail?.pendingActionRequiredItems?.map((task) => ({
              ...task,
              Status: 'Completed',
            }))

          // Create a new array with updated opportunity

          const updatedOpportunityDetail = {
            ...opportunityDetail,
            ActionRequiredItems: {
              ...opportunityDetail.ActionRequiredItems,
              Items: updatedItems,
            },
            pendingActionRequiredItems: updatedPendingActionRequiredItems,
          }
          const updatedOpportunities = [...prevData]
          updatedOpportunities[findOpportunityFromOpportunitiesIndex] =
            categorizeActionItems(updatedOpportunityDetail)

          return updatedOpportunities
        },
      )

      await queryClient.setQueryData(
        ['getTotalActionRequiredCount'],
        (prevData) =>
          opportunityDetail.ActionRequiredCount
            ? prevData - opportunityDetail.ActionRequiredCount
            : prevData,
      )
    } else {
      global.showToast(
        `Unable to upload outstanding documents for <${getProgrammeName(
          opportunityDetail,
        )}> at this time. Please try again later.`,
        { type: 'error' },
      )
    }
  }

  const handleSelectedOpportunity = async (opportunityDetail) => {
    const findOpportunityFromOpportunities = opportunities?.find(
      (item) => item?.Id === opportunityDetail?.WhatId,
    )
    setSelectedOpportunity(opportunityDetail)

    if (
      findOpportunityFromOpportunities &&
      selectedOpportunity?.WhatId !== opportunityDetail?.WhatId
    ) {
      const result = await fetchDocumentTypes(findOpportunityFromOpportunities)
      setDocumentType(result)
    }
    setModalVisible(true)
  }

  const initialPayload = { email: userDetails.email }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: fetchingCMSContent,
  } = useInfiniteCMSContent(initialPayload, opportunities?.length > 0)

  const viewProps = {
    opportunities,
    userName: userDetails.firstName,
    isLoading:
      isFetchingOpportunities ||
      fetchingSpotLightNotification ||
      isBrandThemeLoading ||
      fetchingUpcomingEvents ||
      isFetchingTask ||
      isFetchingActionRequiredCount,
    summaryData,
    modalVisible,
    setModalVisible,
    handleSelectedOpportunity,
    selectedOpportunity,
    setSelectedOpportunity,
    toggleDropdown,
    handleFileUpload,
    dropDownRef,
    dropdownTop,
    dropdownLeft,
    isFileUpload,
    dropdownWidth,
    handleSubmitAllOpportunityTaskFile,
    fetchDocumentTypes,
    isSubmitLoading,
    documentType,
    cmsContent: data?.pages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    notifications,
    handleNextPage,
    handlePrevPage,
    handleUpdateNotification,
    handleEventNavigation,
    currentPage,
    hasSpotLightNextPage,
    hasPrevPage,
    totalPages,
    totalActionRequiredCount,
    events,
    handleEventNextPage,
    handleEventPrevPage,
    currentEventPage,
    hasEventNextPage,
    hasEventPrevPage,
    totalEventPages,
    fetchingCMSContent,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default DashBoard
